@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@500&display=swap');

body {
  box-sizing :border-box;
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  font-style: normal;
  overflow-x: hidden;
  background-color: #fff;
}


header {
  padding: 1.06rem 1rem;
  background-color: #f5f5f5;
  height: 5vh;
  margin-top: -0.63rem;
  margin-right: -0.63rem;
  margin-left: -0.94rem;
  margin-bottom: 1.25rem;
}

header .logo {
  max-width: 100%;
  transform: translate(-8.05rem, -0.75rem);
  height: 8.6vh;
}

.logo-title {
  float: left;
  color:#0766A2;
  font-style: italic;
  font-weight:900;
  transform: translate(5.38rem, 0.50rem);
  max-width: 100%;
  font-style: italic;
}

header a {
  text-decoration: none;
  color: black;
}

.menu {
  float:right;
}

.menu a {
  color: black;
  text-decoration:none;
  display:inline-block;
  padding: 0.63rem 1.25rem;
  border-radius: 0.19rem;
  transition: all 0.3s ease-in-out;
  cursor:pointer;
  font-weight: bold;
}

.menu a:hover {
  background: #0766A2;
  color: #fff;
  cursor:pointer;
}

h1, h2, h3 {
  text-align: center;
}

.controls-container {
  width: 80%;
  max-width: 43.75rem;
  background-color: #fff;
  padding: 1.25rem;

}

.controls {
  text-align: center;
  margin-bottom: 30px;
}

.controls-title {
  margin: auto;
  color:#0766A2;
  font-size: 18px;
  font-weight: 900;
  margin-bottom: 0.31rem;
  margin-top: 0.63rem;

}

.controls label {
  display: block;
  margin-bottom: 16px;
}

.controls input {
  margin-left: 5px;
}

.controls {
  margin-bottom: 20px;
}

.generated-code {
  text-align: left;
  margin-top: 20px;
}

.generated-code pre {
  background: #f4f4f4;
  padding: 10px;
  border: 1px solid #ddd;
  overflow-x: auto;
}

.circle-container {
  width: 100%;
  max-width: 2000px;
  max-height: 2000px;
  overflow: hidden;
}

.circle {
  width: var(--width);
  height: var(--height);
  background: var(--color);
  border-radius: 50%;
  margin: 0 auto;
  display: block;
}

.button {
  color:#fff;
  font-size: 16px;
  font-family: "Raleway", sans-serif;
  border: none;
  font-weight: bolder;
  padding: 0.45rem 3rem;
  background: #0766A2;
  border-radius: 100vw;
  cursor: pointer;
  transition: background-color 250ms;
}

.button:hover {
  background: #14A7cc;
}

.section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 52vh;
  text-align: center;
  margin: 1rem;
  line-height: 2rem;
}

.footer-container {
margin-top: 10rem;
margin-bottom: -5rem;
background: #f4f7f9;
}

.policy-container {
display:flex;
justify-content: center;
height: 8vh;
border-top: 0.13rem solid #f4f7f9;
}

#footer-1 {
margin-top: 1.63rem;
}

#footer-1 a {
color: #0766A2;
font-weight: bold;
text-decoration: none;
cursor: pointer;

}

#footer-1 a:hover {
text-decoration: none;
}

#footer-2 {
display:flex;
justify-content: center;
color:#000;
padding-bottom: 1rem;

}

@media (min-width:1550px) and (max-width: 3200px) {
  .logo-title{
    transform: translate(17.19rem, 1.25rem);
  }

  header .logo {
    transform: translate(-16.25rem, -7.94rem);
  }
}

@media screen and (max-width: 1500px)  {

  .logo-title {
    float: left;
    transform: translate(11.25rem, 0.63rem);

  } 

  header .logo {
    transform: translate(-16.25rem, -5.31rem);
    height: 30vh;
}
}

@media screen and (max-width:1498px) {
    .container {
      display: block;
    }

    header .logo {
      height: 4.63rem;
      width: 4.63rem;
      margin-top: -1.06rem;
      float: left;
      transform: none;
    }

    .logo-title {
      transform: translate(0.06rem, 0.88rem);
    }  

    .controls-container {
      margin: auto;
    }

}

@media screen and (max-width: 768px)  { 

  .menu a 
  {
    display: none;
  }

  header .logo {
    height: 4.63rem;
    width: 4.63rem;
    margin-top: -1.06rem;
    float: right;
    transform: none;
}

  .section {
    display: block;
  }

  .policy-container {
    height: 10vh;
    font-size: 0.63rem;
}

#footer-2 {
  font-size: 0.56rem;
}

}